const Media = {
  id: null,
  disk: '',
  directory: '',
  filename: '',
  extension: '',
  mime_type: '',
  aggregate_type: '',
  size: '',
  url: '',
  created_at: '',
};

export default Media
