<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6">
        <base-input v-if="hideCourseSelect"
                    disabled
                    :label="$t('common.course')"
                    :value="courseName">
        </base-input>
        <base-input
          v-else
          :label="$t('common.course')">
          <el-select
            class="select-default text-uppercase"
            :placeholder="$t('registry.wizard_import.select_course')"
            v-model="providerImportData.course_id"
          >
            <el-option
              v-for="item in courses"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-6">
        <base-input v-if="hideProviderSelect"
                    disabled
                    :label="$t('common.provider')"
                    :value="providerName">
        </base-input>
        <base-input v-else :label="$t('common.provider')">
          <el-select
            class="select-default text-uppercase"
            :placeholder="$t('registry.wizard_import.select_provider')"
            v-model="providerImportData.provider_id"
          >
            <el-option
              v-for="option in providers"
              class="select-default text-uppercase w-100"
              :value="option.id"
              :label="option.name"
              :key="option.id"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
    </div>

    <div class="row d-flex justify-content-center">
      <div class="col-12 col-md-8">
        <ul class="list-group list-group-flush">
          <list-group-item-component
            v-for="(item, index) in providerImportData.data"
            v-bind:key="index"
            :label="index"
            :value="item"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import ProviderImportData from "@/models/providerImportData";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {Select, Option} from 'element-ui';
import {mapGetters} from "vuex";

export default {
  name: "SyncRegistryForm",
  components: {
    ListGroupItemComponent,
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    providerImportData: {
      type: Object,
      default: () => this.$_.cloneDeep(ProviderImportData)
    },
  },
  data() {
    return {
      hideCourseSelect: true,
      hideProviderSelect: true,
    }
  },
  computed: {
    ...mapGetters({
      courses: 'common/allCourses',
      providers: 'common/providers',
    }),

    courseName: function () {
      return this.$_.find(this.courses, (item) => {
        return item.id === this.providerImportData.course_id
      })?.name || '';
    },
    providerName: function () {
      return this.$_.find(this.providers, (item) => {
        return item.id === this.providerImportData.provider_id
      })?.name || '';
    }
  },
  mounted() {
    this.hideCourseSelect = !!this.providerImportData.course_id;
    this.hideProviderSelect = !!this.providerImportData.provider_id;
  }
}
</script>

<style scoped>

</style>
