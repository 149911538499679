<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-12 text-center" v-if="showSuccessMessage">
        <base-alert type="success" class="text-center">
          {{ $t('registry.wizard_import.no_problems') }}
        </base-alert>
      </div>
      <div class="col-auto text-center" v-else>
        <p>{{ $t('registry.wizard_import.synced_provider_import') }}</p>
        <base-alert type="warning" class="text-center mb-0">
          {{ $t('registry.wizard_import.lead_disclaimer') }}
        </base-alert>
        <provider-import-data-datatable
          v-if="isDatatableVisible"
          :provider-import="providerImport"
          :load-on-start="loadProviderImportData"
          @onSuccess="showSuccessMessage = true"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {endpoints} from "@/routes/endpoints";
import ProviderImport from "@/models/providerImport";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import ProviderImportDataDatatable from "@/pages/Registries/datatables/ProviderImportDataDatatable";
import BaseAlert from "@/components/BaseAlert";
import {MessageBox} from "element-ui";

export default {
  name: "RegistryWizardSecondStep",
  components: {BaseAlert, ProviderImportDataDatatable, OctoIcon},
  data() {
    return {
      endpoints: endpoints,
      polling: null,
      loadProviderImportData: false,
      showSuccessMessage: false,
      isDatatableVisible: false
    }
  },
  props: {
    providerImport: {
      type: Object,
      default: () => this.$_.cloneDeep(ProviderImport)
    }
  },
  methods: {
    checkProviderImportStatus() {
      this.$fullLoading.show();
      this.polling = setInterval(() => this.getProviderImport(), 3000);
    },

    getProviderImport() {
      this.$api.get(endpoints.PROVIDER_IMPORT_SHOW.replace('{id}', this.providerImport.id))
        .then((response) => {
          switch (response.data.data.status) {
            case 'synced':
              clearInterval(this.polling)
              this.loadProviderImportData = true;
              this.isDatatableVisible = true;
              this.$fullLoading.hide();
              break;
            case 'error':
              clearInterval(this.polling);
              this.$fullLoading.hide();
              MessageBox({
                title: this.$t('registry.wizard_import.file_error'),
                message: this.$t('registry.wizard_import.reload_file'),
                confirmButtonText: this.$t('common.ok'),
                type: 'warning'
              })
                .finally(() => {
                  this.$emit('onParseError', true)
                });
              break;
            default:
              break;
          }
        }).catch(() => {
        clearInterval(this.polling);
        this.$fullLoading.hide();
        this.$emit('onParseError', true);
      })
    }
  },

  beforeDestroy() {
    clearInterval(this.polling);
    this.$fullLoading.hide();
  }
}
</script>

<style scoped>

</style>
