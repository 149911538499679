<template>
  <vue-dropzone
                class="overflow-auto"
                ref="OctoDropZone"
                id="OctoDropZone"
                :useCustomSlot="true"
                :options="dropzoneOptions"
                v-on:vdropzone-success="uploaded"
                v-on:vdropzone-error="handleError"
                v-on:vdropzone-file-added="startUploading"
  >
    <div class="dropzone-custom-content">
      <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
      <div class="subtitle">...or click to select a file from your computer</div>
    </div>
  </vue-dropzone>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import Media from "@/models/media";
import BaseProgress from "@/components/BaseProgress";
import {mapGetters} from "vuex";

export default {
  name: "OctoDropZone",
  components: {BaseProgress, vueDropzone: vue2Dropzone},
  data() {
    return {
      fileToUpload: Media,
    }
  },
  props: {
    height: {
      type: Number,
      default: 350
    },
    directUpload: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ''
    }
  },
  computed: {

    ...mapGetters({
      accessToken: 'auth/accessToken',
    }),

    dropzoneOptions: function () {
      return {
        url: 'default',
        headers: {},
        params: {},
        method: 'POST',
        withCredentials: true,
        autoProcessQueue: false,
        uploadMultiple: false,
        createImageThumbnails: false,
        addRemoveLinks: false
        // previewTemplate: '<div></div>',
      }

    },
  },
  methods: {
    uploaded: function (file, response) {
        this.$emit('onUploaded', response.data)
    },

    startUploading: async function (file) {

      this.fileToUpload.filename = file.name;
      this.fileToUpload.size = file.size;
      this.fileToUpload.mime_type = file.type;
      await this.handleUpload(this.url)
      this.$refs.OctoDropZone.processQueue();
    },

    handleUpload: function (url) {
      return new Promise((resolve) => {
        this.$refs.OctoDropZone.setOption('url', process.env.VUE_APP_API_URL + '/' + url);
        this.$refs.OctoDropZone.setOption('headers', {
          // 'X-Requested-With': 'XMLHttpRequest',
          // 'X-XSRF-TOKEN': this.getCookie('XSRF-TOKEN'),
          'authorization' : 'Bearer ' + this.accessToken,
          'spa-version': process.env.VUE_APP_VERSION
        });
        resolve();
      });
    },

    getCookie: function (name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      return parts.length === 2
        ? decodeURIComponent(parts.pop().split(";").shift()) :
        ''
    },

    handleError: function () {
      this.$notify({type: 'danger', message: this.$t('notifications.upload_error')});
    },
  }
}
</script>

<style scoped lang="scss">


</style>
