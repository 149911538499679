<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('registry.wizard_import.info_template') }}</p>
        <a :href="importProviderTemplateLink" target="_blank">
          <base-button
            type="success"
            size="sm"
            class="ml-auto text-capitalize">
            {{ $t('registry.wizard_import.download_template') }}
          </base-button>
        </a>
        <p class="mt-3">{{ $t('registry.wizard_import.mandatory_fields') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <octo-drop-zone
          @onUploaded="uploadedImport"
          :url="endpoints.PROVIDER_IMPORT_UPLOAD"/>
      </div>
    </div>

  </div>
</template>

<script>
import OctoDropZone from "@/components/octo-dropzone/OctoDropZone";
import {endpoints} from "@/routes/endpoints";

export default {
  name: "RegistryWizardFirstStep",
  components: {OctoDropZone},
  data() {
    return {
      endpoints: endpoints
    }
  },
  methods: {
    uploadedImport(res) {
      this.$emit("on-validated", res);
    }
  },
  computed: {
    importProviderTemplateLink: function () {
      return process.env.VUE_APP_BUCKET_BASE_URL + '/click-academy-stage/ImportProviderTemplate.xlsx';
    }
  }
}
</script>

<style scoped>

</style>
