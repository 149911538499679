<template>
  <div class="row d-flex justify-content-center">
    <div class="col-md-10">
      <simple-wizard
        ref="simpleWizard"
        @tab-change="tabChange"
        @onCompleted="completeWizard"
        :finishButtonText="$t('common.finish')"
        :nextButtonText="$t('common.next')"
        :prevButtonText="$t('common.prev')"
      >
        <template slot="header">
          <h3 class="card-title">{{ $t('registry.wizard_import.title') }}</h3>
          <h3 class="description">{{ $t('registry.wizard_import.subtitle') }}</h3>
        </template>

        <wizard-tab :before-change="() => validateStep(1)">
          <template slot="label">
            <octo-icon icon="upload"></octo-icon>
            <p>{{ $t('registry.wizard_import.upload') }}</p>
          </template>
          <registry-wizard-first-step
            :key="registryWizardFirstStepKey"
            @on-validated="onStepOneValidated"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(2)">
          <template slot="label">
            <octo-icon icon="checked"></octo-icon>
            <p>{{ $t('registry.wizard_import.check') }}</p>
          </template>
          <registry-wizard-second-step
            @onParseError="parseError"
            ref="registryWizardSecondStep"
            :provider-import="providerImport"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(3)">
          <template slot="label">
            <octo-icon icon="sync"></octo-icon>
            <p>{{ $t('registry.wizard_import.sync') }}</p>
          </template>
          <registry-wizard-third-step
            ref="registryWizardThirdStep"
            :provider-import="providerImport"/>
        </wizard-tab>

      </simple-wizard>
    </div>
  </div>
</template>

<script>
import SimpleWizard from "@/components/Wizard/Wizard";
import WizardTab from "@/components/Wizard/WizardTab";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import RegistryWizardFirstStep from "@/pages/Registries/wizard/RegistryWizardFirstStep";
import ProviderImport from "@/models/providerImport";
import RegistryWizardSecondStep from "@/pages/Registries/wizard/RegistryWizardSecondStep";
import RegistryWizardThirdStep from "@/pages/Registries/wizard/RegistryWizardThirdStep";
import {endpoints} from "@/routes/endpoints";
import {MessageBox} from "element-ui";

export default {
  name: "ImportWizardPage",
  components: {
    RegistryWizardThirdStep,
    RegistryWizardSecondStep, RegistryWizardFirstStep, OctoIcon, WizardTab, SimpleWizard
  },
  data() {
    return {
      providerImport: this.$_.cloneDeep(ProviderImport),
      registryWizardFirstStepKey: 1
    }
  },
  mounted() {

  },
  methods: {
    validateStep(step) {
      let check = false;

      switch (step) {
        case 1:
          check = !!this.providerImport.id;
          break;
        case 2:
        case 3:
          check = true;
          break;
        default:
          check = false;
          break;
      }

      if (!check) {
        this.$notify({type: 'danger', message: this.$t('notifications.wizard_step_error')});
      }

      return check;
    },
    onStepOneValidated(data) {
      this.providerImport = data;
    },
    tabChange(oldTab, newTab) {
      switch (newTab.tabId) {
        case '0':
          break;
        case '1':
          this.$refs.registryWizardSecondStep.checkProviderImportStatus();
          break;
        case '2':
          this.$refs.registryWizardThirdStep.getProviderImportStat();
          break;
        default:
          break;
      }
    },
    completeWizard() {
      this.$api.post(
        endpoints.PROVIDER_IMPORT_REGISTRY_SYNC.replace('{id}', this.providerImport.id),
        {createLead: this.$refs.registryWizardThirdStep.createLead}
      )
        .then(() => {
          MessageBox({
            title: this.$t('common.good_job'),
            message: this.$t('registry.wizard_import.back_on_provider_import_page'),
            confirmButtonText: this.$t('common.ok'),
            type: 'success'
          })
            .finally(() => {
              this.$router.push({name: 'providers.import.index'})
            });
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.job_not_executed')});
        })

    },
    parseError: function () {
      this.registryWizardFirstStepKey++;
      this.$refs.simpleWizard.navigateToTab(0);
    }
  }
}
</script>

<style scoped>

</style>
