<template>
  <div>
    <octo-table
      :enable-store="false"
      :action="endpoints.DATATABLES.providerImportDataMissmatch.replace('{id}', providerImport.id)"
      :fields="fields"
      :filters="filters"
      :load-on-start="loadOnStart"
      :key="keyProviderImportDataDataTable"
      name="ProviderImportDataDataTable"
      @onNoResults="$emit('onSuccess')"
      @onResetFilters="resetFilters"
    >

      <template v-slot:personal_data="data">
        <div class="text-uppercase text-truncate small">{{ data.row.surname }} {{ data.row.name }}</div>
        <div class="text-truncate small">{{ data.row.email }}</div>
      </template>

      <template v-slot:course="data">
        <div v-if="data.row.course && data.row.course.id">
          <div class="text-uppercase small">{{ data.row.course.name }}</div>
        </div>
        <div v-else>
          <octo-icon icon="wrong"/>
        </div>
      </template>

      <template v-slot:provider="data">
        <div v-if="data.row.provider && data.row.provider.id">
          <div class="text-uppercase small">{{ data.row.provider.name }}</div>
        </div>
        <div v-else>
          <octo-icon icon="wrong"/>
        </div>
      </template>

      <template v-slot:actions="data">
        <div class="d-flex justify-content-end">
          <el-tooltip :content="$t('common.info')" :open-delay="300" placement="top">
            <base-button class="mx-1" size="sm" icon link @click="showModalData(data.row)">
              <octo-icon icon="link"/>
            </base-button>
          </el-tooltip>
        </div>

      </template>
    </octo-table>

    <modal centered :show.sync="showModal" modalClasses="modal-lg">
      <template slot="header">
        <h5 class="modal-title">{{ $t('common.fill-data') }}</h5>
      </template>
      <sync-registry-form
        :provider-import-data="providerImportDataSelected"
        :key="`syncRegistryForm-${syncRegistryFormKey}`"
      />
      <template slot="footer">
        <base-button link class="text-capitalize" :disabled="!canSaveData" @click="onSaveModalData">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>

  </div>

</template>


<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import ProviderImport from "@/models/providerImport";
import Modal from "@/components/Modal";
import {Select, Option} from 'element-ui';
import ProviderImportData from "@/models/providerImportData";
import SyncRegistryForm from "@/pages/Registries/forms/SyncRegistryForm";


export default {
  name: "ProviderImportDataDatatable",
  components: {
    SyncRegistryForm,
    Modal,
    OctoIcon,
    OctoTable,
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    loadOnStart: {
      type: Boolean,
      default: true
    },
    providerImport: {
      type: Object,
      default: () => this.$_.cloneDeep(ProviderImport)
    },
  },
  data() {
    return {
      showModal: false,
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'personal_data', label: 'personal_data', minWidth: 200, slot: true},
        {prop: 'course', label: 'course', slot: true, align: 'center'},
        {prop: 'provider', label: 'provider', slot: true, align: 'center'},
        {prop: 'actions', label: 'actions', slot: true},
      ],
      providerImportDataSelected: this.$_.cloneDeep(ProviderImportData),
      syncRegistryFormKey: 1,
      keyProviderImportDataDataTable: 1,
    }
  },
  computed: {
    canSaveData: function () {
      let result = true;
      if (this.providerImportDataSelected.course_id === null) result = false;
      if (this.providerImportDataSelected.provider_id === null) result = false;
      return result;
    }
  },
  methods: {


      resetFilters: function () {
        this.filters = {

        };
      },

    showModalData(data) {
      this.providerImportDataSelected = this.$_.cloneDeep(data);
      this.syncRegistryFormKey++;
      this.showModal = true;
    },
    onSaveModalData() {

      let data = {
        course_id: this.providerImportDataSelected.course_id,
        provider_id: this.providerImportDataSelected.provider_id
      };

      this.$api.put(
        endpoints.PROVIDER_DATA_IMPORT_UPDATE.replace("{id}", this.providerImportDataSelected.id),
        data
      )
        .then(() => {
          this.keyProviderImportDataDataTable++;
          this.showModal = false;
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        });

    }
  }

}
</script>

<style scoped>

</style>
