import { render, staticRenderFns } from "./RegistryWizardThirdStep.vue?vue&type=template&id=d1bf10e0&scoped=true&"
import script from "./RegistryWizardThirdStep.vue?vue&type=script&lang=js&"
export * from "./RegistryWizardThirdStep.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1bf10e0",
  null
  
)

export default component.exports