<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('registry.wizard_import.step_3_info') }}</p>
      </div>
    </div>
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('registry.wizard_import.step_3_create_lead') }}</p>
        <el-switch class="mt-2" v-model="createLead">
          <octo-icon icon="checked" slot="on"></octo-icon>
          <octo-icon icon="wrong" slot="off"></octo-icon>
        </el-switch>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4" v-for="(card, index) in statsCards" :key="index">
        <stats-card
          class="shadow"
          :title="card.title"
          :sub-title="card.subTitle"
          :type="card.type"
          :icon="card.icon"
        >
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div clasS="col-12 col-md-6">
        <card class="text-left shadow" body-classes="standard-card-body">
          <template slot="header">
            <h6 class="title d-inline">{{ $t('registry.wizard_import.group_by_provider') }}</h6>
          </template>

          <ul class="list-group list-group-flush m-0">
            <list-group-item-component
              v-for="(provider, slugProvider) in providersData"
              v-bind:key="slugProvider"
              :label="provider.name"
              :value="provider.count"
            />
          </ul>

        </card>
      </div>
      <div clasS="col-12 col-md-6">
        <card class="text-left shadow" body-classes="standard-card-body">
          <template slot="header">
            <h6 class="title d-inline">{{ $t('registry.wizard_import.group_by_course') }}</h6>
          </template>

          <ul class="list-group list-group-flush m-0">
            <list-group-item-component
              v-for="(course, slugCourse) in coursesData"
              v-bind:key="slugCourse"
              :label="course.name"
              :value="course.count"
            />
          </ul>

        </card>
      </div>
    </div>
  </div>
</template>

<script>

import {endpoints} from "@/routes/endpoints";
import ProviderImport from "@/models/providerImport";
import StatsCard from "@/components/Cards/StatsCard";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import OctoTable from "@/components/octo-table/OctoTable";
import BaseTable from "@/components/BaseTable";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {Switch} from "element-ui";

export default {
  name: "RegistryWizardThirdStep",
  components: {
    ListGroupItemComponent,
    BaseTable,
    OctoTable,
    OctoIcon,
    StatsCard,
    [Switch.name]: Switch,
  },
  data() {
    return {
      endpoints: endpoints,
      createLead: true,
      coursesData: [],
      providersData: [],
      statsCards: {
        total_number: {
          title: '0',
          subTitle: this.$t('registry.wizard_import.total_number'),
          type: 'success',
          icon: 'list'
        },
        missmatches: {
          title: '0',
          subTitle: this.$t('registry.wizard_import.missmatches'),
          type: 'danger',
          icon: 'broken-link'
        },
        import_amount: {
          title: '0',
          subTitle: this.$t('registry.wizard_import.import_amount'),
          type: 'info',
          icon: 'euro'
        }
      },
    }
  },
  props: {
    providerImport: {
      type: Object,
      default: () => this.$_.cloneDeep(ProviderImport)
    }
  },
  mounted() {

  },
  methods: {
    getProviderImportStat: function () {

      this.$api.get(
        endpoints.PROVIDER_IMPORT_STATS.replace('{id}', this.providerImport.id),
      )
        .then((resp) => {

          this.statsCards.total_number.title = resp?.data?.totals?.records.toString() || '0';
          this.statsCards.missmatches.title = resp?.data?.totals?.rejected.toString() || '0';
          this.statsCards.import_amount.title = this.$options.filters.euro(resp?.data?.totals?.price || 0);
          this.providersData = resp?.data?.providers || [];
          this.coursesData = resp?.data?.courses || [];

        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        });

    },
  }

}
</script>

<style scoped>

</style>
